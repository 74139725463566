// Corpo dos Card de Atendimento
import React, { useState, useEffect, useReducer, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import { TiFlashOutline } from "react-icons/ti";
import { Widgets } from "@material-ui/icons";
import { FaRegCheckSquare, FaRegWindowClose } from "react-icons/fa";
import api from "../../services/api";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    maxHeight: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#DDE3EC",
    paddingTop: "10px",
    position: "relative",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerBtnAtendimentos: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    textAlign: "end",
    position: "absolute",
    paddingRight: "10px",
    top: "88%",
    right: "10px",
  },
  btnActionAtendimentos: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    border: "none",
    backgroundColor: "#1A4783",
    borderRadius: "100%",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transition: "all .2s ease-in-out",
      backgroundColor: "#0055c5",
    },
  },
  containerDropBtn: {},
  btnActionAtendimento: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "14px",
    border: "none",
    backgroundColor: "#1A4783",
    transition: "all .2s ease-in-out",
    cursor: "pointer",
    color: "white",
    "&:hover": {
      transition: "all .2s ease-in-out",
      backgroundColor: "#0055c5",
    },
    borderRadius: "8px",
    padding: "12px",
  },
  containerBtnActionAtendimento: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_TAGS") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].tags = ticket.tags;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsListCustom = (props) => {
  const {
    status,
    searchParam,
    tags,
    users,
    showAll,
    selectedQueueIds,
    updateCount,
    style,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, tags, users, selectedQueueIds]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tags),
    users: JSON.stringify(users),
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    const queueIds = queues.map((q) => q.id);
    const filteredTickets = tickets.filter(
      (t) => queueIds.indexOf(t.queueId) > -1
    );

    if (profile === "user") {
      dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
    } else {
      dispatch({ type: "LOAD_TICKETS", payload: tickets });
    }
  }, [tickets, status, searchParam, queues, profile]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    const shouldUpdateTicket = (ticket) =>
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on(`company-${companyId}-ticket`, (data) => {
      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }

      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }

      if (data.action === "tagUpdate") {
        dispatch({
          type: "UPDATE_TICKET_TAGS",
          payload: data.ticket,
        });
      }
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      const queueIds = queues.map((q) => q.id);
      if (
        profile === "user" &&
        (queueIds.indexOf(data.ticket.queue?.id) === -1 ||
          data.ticket.queue === null)
      ) {
        return;
      }

      if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload: data.ticket,
        });
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds, tags, users, profile, queues]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.length);

      if (status === "pending") {
        if (ticketsList.length > 0) {
          document.title = `(${ticketsList.length}) ConnectZapper`;
        } else {
          document.title = `ConnectZapper`;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  const [dropBtnAtendimento, setDropBtnAtendimento] = useState(true);

  const handleBtnAtendimento = () => {
    setDropBtnAtendimento(!dropBtnAtendimento);
  };
  // Aceitar Todos os Atendimentos
  const pendingTickets = ticketsList.filter(
    (ticket) => ticket.status === "pending"
  );
  const handleAcceptAllTickets = async (tickets) => {
    setLoadingTicket(true);

    try {
      // Itera sobre todos os tickets com status "pending"
      const acceptPromises = tickets.map(async (ticket) => {
        if (ticket.status === "pending") {
          return api.put(`/tickets/${ticket.id}`, {
            status: "open",
            userId: user?.id,
          });
        }
      });

      // Espera todas as requisições serem completadas
      await Promise.all(acceptPromises);
    } catch (err) {
      console.log("Não foi possivel aceitar todos");
    } finally {
      setLoadingTicket(false);
    }
  };
  // Fechar todos atendimentos
  const [loadingTicket, setLoadingTicket] = useState(false);

  const handleCloseTicket = async (tickets) => {
    setLoadingTicket(true); // Inicia o loading
    try {
      const closeTicketPromises = tickets.map((ticket) =>
        api.put(`/tickets/${ticket.id}`, {
          status: "closed",
          userId: user?.id || null,
          disableBot: true,
        })
      );

      await Promise.all(closeTicketPromises); // Espera o fechamento de todos os tickets
      setLoadingTicket(false); // Finaliza o loading
    } catch (error) {
      console.error("Erro ao fechar tickets: ", error);
      setLoadingTicket(false); // Garante que o loading pare mesmo em caso de erro
    }
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0, paddingRight: 10 }}>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {ticketsList.map((ticket) => (
                <TicketListItem ticket={ticket} key={ticket.id} />
              ))}
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
      <div className={classes.containerBtnAtendimentos}>
        <button
          onClick={handleBtnAtendimento}
          className={classes.btnActionAtendimentos}
        >
          <TiFlashOutline style={{ fontSize: "20px", color: "white" }} />
        </button>
        <div
          className={classes.containerDropBtn}
          style={{
            display: dropBtnAtendimento ? "none" : "block",
            position: "absolute",
            top: "-120px",
          }}
        >
          <ul className={classes.containerBtnActionAtendimento}>
            <li>
              <button
                disabled={loadingTicket}
                onClick={() => handleAcceptAllTickets(ticketsList)}
                className={classes.btnActionAtendimento}
              >
                <p style={{ margin: "0px" }}>Aceitar Todos</p>
                <FaRegCheckSquare style={{ fontSize: "16px" }} />
              </button>
            </li>
            <li>
              <button
                onClick={() => handleCloseTicket(ticketsList)}
                className={classes.btnActionAtendimento}
              >
                <p style={{ margin: "0px" }}>Fechar Todos</p>
                <FaRegWindowClose style={{ fontSize: "16px" }} />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Paper>
  );
};

export default TicketsListCustom;
